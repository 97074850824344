exports.shouldUpdateScroll = () => {
  return window.__navigatingToLink;
};

exports.onInitialClientRender = () => {
  // ((document.getElementById('___loader') || {}).style || {}).display = 'block';
};

// exports.onServiceWorkerUpdateFound = () => {
//   if (
//     window.confirm(
//       "This site has been updated with new data. Do you wish to reload the site to get the new data?"
//     )
//   ) {
//     window.location.reload(true);
//   }
// };

// exports.onServiceWorkerUpdateReady = () => window.location.reload(true);

exports.onServiceWorkerUpdateReady = () => {
  console.info('PWA update available.');
  // if (
  //   window.confirm('An update is available. Click on OK to update the website.')
  // ) {
  //   window.location.reload(true);
  // }
  window.location.reload(true);
};
